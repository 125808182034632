<template>
  <div class="pa-2">
    <v-card-text class="pt-4">
      <v-row>
        <v-form ref="replaceForm" class="w-100">
          <v-col cols="12">
            <v-text-field
              :rules="[rules.required]"
              required
              v-model="old_domain"
              label="Old Domain *"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              :rules="[rules.required]"
              required
              v-model="new_domain"
              label="New Domain *"
            ></v-text-field>
          </v-col>
        </v-form>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="blue darken-1" text @click="closeForm">
        Close
      </v-btn>
      <v-btn
        :loading="loading"
        color="blue darken-1"
        text
        type="button"
        @click="onReplaceDomains"
      >
        Save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { requestStatus } from "@/services/services";
import { domainsReplace } from "@/store/modules/domains/api";
export default {
  props: ["callback", "close_dialog"],
  data() {
    return {
      old_domain: "",
      new_domain: "",
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required",
      },
    };
  },
  methods: {
    onReplaceDomains() {
      if (this.$refs.replaceForm.validate()) {
        this.loading = true;
        domainsReplace({
          old_domain: this.old_domain,
          new_domain: this.new_domain,
        })
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Domains have been replaced");
              this.callback();
              this.closeForm();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    closeForm() {
      this.old_domain = "";
      this.new_domain = "";
      this.$refs.replaceForm.resetValidation();
      this.close_dialog();
    },
  },
};
</script>
