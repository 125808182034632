<template>
  <div class="pa-2">
    <v-card-text class="pt-4">
      <v-row>
        <v-form ref="migrateForm" class="w-100">
          <v-col cols="12">
            <ServerSelect
              ref="OldServerSelectRef"
              :label="'Old server *'"
              :changeValue="(value) => (old_server = value)"
              :rules="rules.required"
              :hideDetails="false"
            />
          </v-col>
          <v-col cols="12">
            <ServerSelect
              ref="NewServerSelectRef"
              :label="'New server *'"
              :changeValue="(value) => (new_server = value)"
              :rules="rules.required"
              :hideDetails="false"
            />
          </v-col>
        </v-form>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="blue darken-1" text @click="closeForm">
        Close
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        type="button"
        @click="onReplaceDomains"
        :loading="loading"
      >
        Save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { requestStatus } from "@/services/services";
import { domainsMigrate } from "@/store/modules/domains/api";
import ServerSelect from "../ServerSelect.vue";
export default {
  props: ["callback", "close_dialog"],
  components: {
    ServerSelect,
  },
  data() {
    return {
      new_server: "",
      old_server: "",
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required",
      },
    };
  },
  methods: {
    onReplaceDomains() {
      if (this.$refs.migrateForm.validate()) {
        this.loading = true;
        domainsMigrate({
          old_server: +this.old_server.id,
          new_server: +this.new_server.id,
        })
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Domains have been migrated");
              this.callback();
              this.closeForm();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    closeForm() {
      this.old_server = "";
      this.new_server = "";
      this.$refs.OldServerSelectRef.clear();
      this.$refs.NewServerSelectRef.clear();
      this.$refs.migrateForm.resetValidation();
      this.close_dialog();
    },
  },
};
</script>
