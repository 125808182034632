<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="ma-2" v-bind="attrs" v-on="on">
              Create Domain
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip>
      </div>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">Create Domain</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div>
            <v-form ref="createDomain" class="w-100">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="domain"
                    label="Domain *"
                    required
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="server"
                    :items="serversList"
                    :loading="isLoadingServerss"
                    :search-input.sync="searchServer"
                    @focus="() => onLoadServer()"
                    placeholder="Search"
                    item-text="name"
                    item-value="id"
                    label="Server *"
                    required
                    :rules="[rules.required]"
                  >
                    <template #item="{ item }">
                      <div>{{ item.name }} / {{ item.ip }}</div>
                    </template></v-autocomplete
                  >
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="registrator"
                    :items="registratorsList"
                    :loading="isLoadingregistrators"
                    :search-input.sync="searchRegistrator"
                    @focus="() => onLoadRegistrators()"
                    placeholder="Search"
                    item-text="name"
                    item-value="id"
                    label="Registrator *"
                    required
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex">
                    <div class="mr-4 custom-date-picker" style="width: 50%">
                      <!-- <span>Registration date</span> -->
                      <date-picker
                        style="width: 100%"
                        v-model="registration_date"
                        type="date"
                        placeholder="Registration date"
                        value-type="format"
                        format="YYYY-MM-DD"
                        class="mb-2"
                      ></date-picker>
                    </div>
                    <div style="width: 50%" class="custom-date-picker">
                      <!-- <span>Expiration date</span> -->
                      <date-picker
                        style="width: 100%"
                        v-model="expiration_date"
                        type="date"
                        placeholder="Expiration date"
                        value-type="format"
                        format="YYYY-MM-DD"
                        class="mb-2"
                      ></date-picker>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="is_loading"
          color="blue darken-1"
          text
          @click="closeDialog"
        >
          Close
        </v-btn>
        <v-btn
          :loading="is_loading"
          color="blue darken-1"
          text
          type="button"
          @click="onCreateClick"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { requestStatus } from "@/services/services";
import {
  createDomain,
  loadServers,
  loadRegistrators,
} from "@/store/modules/domains/api";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "Create",
  props: ["updadeList"],
  components: {
    DatePicker,
  },
  data: () => ({
    dialog: false,
    domain: "",
    registrator: "",
    registratorsList: [],
    isLoadingregistrators: false,
    searchRegistrator: "",
    server: "",
    serversList: [],
    isLoadingServerss: false,
    searchServer: "",
    registration_date: null,
    expiration_date: null,
    is_loading: false,
    valid: false,
    rules: {
      required: (value) => !!value || "This field is required",
    },
  }),
  watch: {
    dialog(dialog) {
      if (!dialog) {
        this.domain = "";
        this.server = "";
        this.registrator = "";
        this.searchRegistrator = "";
        this.searchServer = "";
        this.registration_date = null;
        this.expiration_date = null;
        this.$refs.createDomain.resetValidation();
      }
    },
    searchServer() {
      this.onWaitChangeDomain();
    },
    searchRegistrator() {
      this.onWaitChangeRegistrator();
    },
  },
  methods: {
    onWaitChangeRegistrator() {
      if (
        this.searchRegistrator &&
        (this.searchRegistrator.length >= 2 || this.searchRegistrator === "")
      ) {
        this.onLoadRegistrators(this.searchRegistrator);
      }
    },
    onLoadRegistrators(search) {
      loadRegistrators(`search=${search ? search : ""}`).then((response) => {
        if (response.status === requestStatus.success) {
          this.registratorsList = response.response.data.results;
        }
      });
    },
    onWaitChangeDomain() {
      if (
        this.searchServer &&
        (this.searchServer.length >= 2 || this.searchServer === "")
      ) {
        if (this.searchServer != `${this.server.name}/${this.server.ip}`)
          this.onLoadServer(this.searchServer);
      }
    },
    onLoadServer(search) {
      loadServers(`search=${search ? search : ""}`).then((response) => {
        if (response.status === requestStatus.success) {
          this.serversList = response.response.data.results;
        }
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    onCreateClick() {
      if (this.$refs.createDomain.validate()) {
        this.is_loading = true;
        let data = {
          domain: this.domain,
          server: +this.server,
          registrator: +this.registrator,
        };

        if (this.registration_date)
          data["registration_date"] = this.registration_date;
        if (this.expiration_date)
          data["expiration_date"] = this.expiration_date;

        createDomain(data)
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Domain have been created");
              this.updadeList();
              this.closeDialog();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.is_loading = false));
      }
    },
  },
};
</script>

<style lang="scss">
.custom-date-picker {
  .mx-input:hover,
  .mx-input:focus {
    border-color: #1976d2 !important;
  }
}
</style>
