<template>
  <div>
    <v-autocomplete
      v-model="server"
      :items="serversList"
      :loading="isLoadingServerss"
      :search-input.sync="searchServer"
      @focus="() => onLoadServer()"
      placeholder="Search"
      item-text="name"
      item-value="id"
      :label="label ? label : ''"
      required
      return-object
      :hide-details="hideDetails"
      @change="changeValue(server)"
      clearable
      :rules="[rules ? rules : null]"
    >
      <template #item="{ item }">
        <div>{{ item.name }} / {{ item.ip }}</div>
      </template></v-autocomplete
    >
  </div>
</template>

<script>
import { loadServers } from "@/store/modules/domains/api";
import { requestStatus } from "@/services/services";

export default {
  props: ["changeValue", "label", "rules", "hideDetails"],
  data() {
    return {
      server: "",
      serversList: [],
      isLoadingServerss: false,
      searchServer: "",
    };
  },
  watch: {
    searchServer() {
      this.onWaitChangeDomain();
    },
  },
  methods: {
    clear() {
      this.server = "";
      this.searchServer = "";
    },
    onWaitChangeDomain() {
      if (
        this.searchServer &&
        (this.searchServer.length >= 2 || this.searchServer === "")
      ) {
        this.onLoadServer(this.searchServer);
      }
    },
    onLoadServer(search) {
      loadServers(`search=${search ? search : ""}`).then((response) => {
        if (response.status === requestStatus.success) {
          this.serversList = response.response.data.results;
        }
      });
    },
  },
};
</script>

<style></style>
