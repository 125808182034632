<template>
  <div class="pa-2 change-server-wrp">
    <v-card-text class="pt-4">
      <v-row>
        <v-form ref="changeServerForm" class="w-100">
          <v-col cols="12">
            <v-autocomplete
              chips
              v-model="select_domains"
              :items="domainsList"
              :loading="isLoadingDomains"
              :search-input.sync="searchDomain"
              @focus="() => loadDomainsList()"
              placeholder="Search"
              item-text="domain"
              item-value="id"
              required
              return-object
              multiple
              clearable
              :rules="[rules.requiredLength]"
              deletable-chips
              label="Domains *"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <ServerSelect
              ref="NewServerSelectRef"
              :label="'New server *'"
              :changeValue="(value) => (new_server = value)"
              :rules="rules.required"
              :hideDetails="false"
            />
          </v-col>
        </v-form>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" color="blue darken-1" text @click="closeForm">
        Close
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        type="button"
        @click="onReplaceDomains"
        :loading="loading"
      >
        Save
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { requestStatus } from "@/services/services";
import { domainsMigrate } from "@/store/modules/domains/api";
import ServerSelect from "../ServerSelect.vue";
import _ from "lodash";
import { loadList } from "@/store/modules/domains/api";
export default {
  props: ["domain", "callback", "close_dialog"],
  components: {
    ServerSelect,
  },
  data() {
    return {
      new_server: "",
      loading: false,
      select_domains: [],
      isLoadingDomains: false,
      searchDomain: "",
      domains: [],
      rules: {
        required: (value) => !!value || "This field is required",
        requiredLength: (value) =>
          !!(value && value.length) || "This field is required",
      },
    };
  },
  watch: {
    searchDomain() {
      this.onWaitChangeDomain();
    },
  },
  computed: {
    domainsList() {
      return this.select_domains
        ? [...this.domains, ...this.select_domains]
        : this.domains;
    },
  },
  mounted() {
    if (this.domain) {
      this.domains.push(this.domain);
      this.select_domains.push(this.domain);
      this.dialog = true;
    }
  },
  methods: {
    onWaitChangeDomain() {
      if (
        (this.searchDomain || this.searchDomain === "") &&
        (this.searchDomain.length >= 2 || this.searchDomain === "")
      ) {
        this.loadDomainsList(this.searchDomain);
      }
    },
    loadDomainsList(search) {
      loadList(
        `is_active=true&is_banned=false&ordering=-id&search=${
          search ? search : ""
        }`
      ).then((response) => {
        this.domains = response.response.data.results;
      });
    },
    onReplaceDomains() {
      if (this.$refs.changeServerForm.validate()) {
        this.loading = true;
        let data = {
          domains: this.select_domains.map((item) => item.id),
          new_server: +this.new_server.id,
        };
        domainsMigrate(data)
          .then((response) => {
            if (response.status === requestStatus.success) {
              this.$toaster.success("Server have been changed");
              this.callback();
              this.closeForm();
            } else {
              this.$toaster.error(
                response.messages || "Oops, something went wrong."
              );
            }
          })
          .finally(() => (this.loading = false));
      }
    },

    closeForm() {
      this.new_server = "";
      this.$refs.NewServerSelectRef.clear();
      this.domains = [];
      this.select_domains = [];
      this.$refs.changeServerForm.resetValidation();
      this.close_dialog();
    },
  },
};
</script>

<style lang="scss">
.change-server-wrp {
  .v-select.v-select--chips .v-select__selections {
    min-height: 32px !important;
  }
}
</style>
