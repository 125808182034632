<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="info" v-bind="attrs" v-on="on">
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </template>
          <span>Settings</span>
        </v-tooltip>
      </div>
    </template>

    <v-card>
      <div>
        <v-tabs v-model="tab" background-color="transparent" color="info" grow>
          <v-tab class="pt-3 pb-3"> Replace Domain </v-tab>
          <v-tab class="pt-3 pb-3"> Domain Migrate </v-tab>
          <v-tab class="pt-3 pb-3"> Change server </v-tab>
        </v-tabs>
      </div>
      <v-divider></v-divider>

      <v-tabs-items v-if="dialog" v-model="tab">
        <v-tab-item>
          <DomainReplaceContent
            :callback="() => updadeList()"
            :close_dialog="() => closeModal()"
          />
        </v-tab-item>
        <v-tab-item>
          <DomainMigrateContent
            :callback="() => updadeList()"
            :close_dialog="() => closeModal()"
          />
        </v-tab-item>
        <v-tab-item>
          <ChangeDomainsServerContent
            :callback="() => updadeList()"
            :close_dialog="() => closeModal()"
            :domain="domain"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import DomainReplaceContent from "./settings/DomainReplaceContent.vue";
import DomainMigrateContent from "./settings/DomainMigrateContent.vue";
import ChangeDomainsServerContent from "./settings/ChangeDomainsServerContent.vue";
export default {
  props: ["updadeList"],
  components: {
    DomainReplaceContent,
    DomainMigrateContent,
    ChangeDomainsServerContent,
  },
  data() {
    return {
      tab: 0,
      dialog: false,
      domain: null,
    };
  },
  mounted() {
    window.getApp.$on("OPEN_DOMAIN_SETTINGS", (domain) => {
      if (domain) {
        this.domain = domain;
        this.tab = 2;
      }
      this.dialog = true;
    });
  },
  methods: {
    closeModal() {
      this.tab = 0;
      this.domain = null;
      this.dialog = false;
    },
  },
};
</script>
